import { useEffect } from "react";
import useTitle from "./useTitle";

interface TitledProps {
  title: string;
  children: React.ReactElement;
}

export default function Titled(props: TitledProps) {
  const { setSubTitle } = useTitle();

  useEffect(() => {
    setSubTitle(props.title);
    return () => {
      setSubTitle("");
    };
  }, [props.title, setSubTitle]);

  return props.children;
}
