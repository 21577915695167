import { createContext, useContext } from "react";
import { User } from "../../api/models/User.model";

interface AuthContextType {
  user: User;

  login: (
    mail: string,
    password: string,
    onSuccess?: VoidFunction,
    onFail?: VoidFunction
  ) => void;

  logout: (callback?: VoidFunction) => void;
}

export const AuthContext = createContext<AuthContextType>(null!);

/**
 * provides authentication context which can be used to login and logout the user
 * and also access the user object.
 * @returns context object
 */
export default function useAuth() {
  return useContext(AuthContext);
}
