import { Edit } from "@mui/icons-material";
import { BaseTextFieldProps, InputAdornment, TextField } from "@mui/material";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import "./textInput.scss";

interface TextInputProps {
  startValue?: string;
  onConfirm?: (input: string) => void;
  onChange?: (input: string) => void;
  onErrorStateChange?: (error: boolean) => void;
  clearOnConfirm?: boolean;
  label?: string;
  showErrorOnFocus?: boolean;
}

export default function TextInput(props: TextInputProps & BaseTextFieldProps) {
  const {
    startValue,
    onConfirm,
    onChange,
    onErrorStateChange,
    clearOnConfirm,
    label,
    showErrorOnFocus,
  } = props;

  const [input, setInput] = useState<string>(startValue ?? "");
  const [error, setError] = useState<boolean>(true);
  const [isFocussed, setIsFocussed] = useState<boolean>(false);

  const onTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInput(event.target.value);
    },
    []
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        onConfirm?.(input);

        if (clearOnConfirm) {
          setInput("");
        }
      }
    },
    [onConfirm, input, clearOnConfirm]
  );

  const onFocus = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      console.log("is current target:" + event.currentTarget);
      setIsFocussed(true);
    },
    []
  );

  const errorState = useCallback(() => {
    return (
      (!showErrorOnFocus && error) || (error && showErrorOnFocus && isFocussed)
    );
  }, [error, showErrorOnFocus, isFocussed]);

  useEffect(() => {
    setInput(startValue ?? "");
  }, [startValue]);

  useEffect(() => {
    onChange?.(input);
    setError(input.replaceAll(" ", "") === "");
  }, [input, onChange]);

  useEffect(() => {
    onErrorStateChange?.(error);
  }, [error, onErrorStateChange]);

  return (
    <TextField
      className="textFieldGeneral"
      sx={props.sx}
      variant="outlined"
      color="error"
      onChange={onTextFieldChange}
      value={input}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      label={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Edit />
          </InputAdornment>
        ),
      }}
      error={errorState()}
      helperText={errorState() ? `${label} darf nicht leer sein!` : ""}
    ></TextField>
  );
}
