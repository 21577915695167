import { Button, IconButton, SvgIconProps } from '@mui/material';

import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { Delete } from '@mui/icons-material';
import { TRUE } from 'sass';
import { useState } from 'react';

interface DeleteButtonProps {
  item: string;
  onConfirm: VoidFunction;
  Icon?: React.ComponentType<SvgIconProps>;
}

// export default function DeleteButton(props: DeleteButtonProps) {
export default function DeleteButton({ item, onConfirm, Icon = Delete }: DeleteButtonProps) {
  const [open, setOpen] = useState<boolean>(false);

  const onclick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton color='error' onClick={() => onclick()}>
        <Icon />
      </IconButton>
      <ConfirmationDialog
        dialogueIcon={true}
        title={`${item} LÖSCHEN`}
        open={open}
        children={`Möchten Sie ${item} wirklich unwiderruflich löschen?`}
        onClose={onClose}
        onAccept={() => onConfirm?.()}
      ></ConfirmationDialog>
    </>
  );
}
