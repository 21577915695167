import "./confirmationDialog.scss";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import WarningIcon from "@mui/icons-material/Warning";

export interface ConfirmationDialogProps {
  open: boolean;
  title?: string;
  confirmDisabled?: boolean;
  dialogueIcon?: boolean;
  onClose?: VoidFunction;
  onAccept?: VoidFunction;
  onAbort?: VoidFunction;
}

export default function ConfirmationDialog(
  props: ConfirmationDialogProps & { children: React.ReactChild }
) {
  const [open, setOpen] = useState<boolean>(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props]);

  const onClose = () => {
    setOpen(false);
    props.onClose?.();
  };

  const onAccept = () => {
    onClose();
    props.onAccept?.();
  };

  const onAbort = () => {
    onClose();
    props.onAbort?.();
  };

  return (
    <Dialog
      className="dialogue"
      open={open}
      onClose={() => onClose()}
      onBackdropClick={() => onAbort()}
      maxWidth={"xl"}
    >
      <div className="dialogueDiv">
        <div className="dialogueTitleBox">
          <DeleteOutlineIcon
            className={
              props.dialogueIcon ? "dialogueDelIcon" : "noDialogueDelIcon"
            }
          />
          {props.title && <DialogTitle>{props.title}</DialogTitle>}
        </div>
        <div className="contentActionDiv">
          <DialogContent className="dialogueContent">
            {props.children}
          </DialogContent>

          <DialogActions className="dialogueActions">
            <Button
              className="cancelBtn"
              variant="contained"
              onClick={() => onAbort()}
            >
              Abbrechen
            </Button>
            <Button
              className="successBtn"
              variant="contained"
              onClick={() => onAccept()}
              disabled={props.confirmDisabled}
            >
              Bestätigen
            </Button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
}
