import './createStorageSegmentDialog.scss';

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ConfirmationDialog, { ConfirmationDialogProps } from './ConfirmationDialog';
import Meter, { MeterCreateInput } from '../../api/models/Meter.model';
import { useCallback, useEffect, useReducer, useState } from 'react';

import TextInput from '../input/TextInput';

interface CreateMeterDialogProps {
  onSubmit?: (data: MeterCreateInput) => void;
  meter?: Meter;
}

const DEVICE_TYPES = {
  GATEWAY: 'gateway',
  METER: 'meter',
  SMOKEDETECTOR: 'smokedetector',
};

export default function CreateMeterDialog(props: CreateMeterDialogProps & ConfirmationDialogProps) {
  const { onSubmit, meter } = props;
  const [formState, setFormState] = useState<MeterCreateInput>(null!);

  const reducer = (
    state: Map<string, boolean>,
    action: { type: 'add' | 'remove' | 'reset'; deviceId: string }
  ) => {
    switch (action.type) {
      case 'add':
        state.set(action.deviceId, true);
        break;
      case 'remove':
        state.set(action.deviceId, false);
        break;
      case 'reset':
        state = new Map<string, boolean>();
    }

    return state;
  };

  // this error state is a map that maps the input field names to their error state.
  // only when all values of this map are false the form is error free
  const [errors, dispatch] = useReducer(reducer, new Map<string, boolean>());

  const hasErrors = useCallback(() => {
    const iterator = errors.entries();
    let current = iterator.next();

    while (!current.done) {
      // current.value is an array containing the key and value of the current map entry
      // we want to check the value, so we use the 2nd element of that array (with index 1)
      if (current.value[1]) {
        return true;
      }

      current = iterator.next();
    }

    return false;
  }, [errors]);

  const onSubmitConfirmation = useCallback(() => {
    onSubmit?.(formState);
    dispatch({ type: 'reset', deviceId: '' });
  }, [formState, onSubmit]);

  const onFormChange = useCallback((state: MeterCreateInput) => {
    setFormState(state);
  }, []);

  return (
    <ConfirmationDialog
      title='Neues Gerät'
      onAccept={() => onSubmitConfirmation()}
      confirmDisabled={hasErrors()}
      onAbort={() => dispatch({ type: 'reset', deviceId: '' })}
      {...props}
    >
      <CreateMeterSegmentForm onChange={onFormChange} dispatch={dispatch} meter={meter} />
    </ConfirmationDialog>
  );
}

interface CreateMeterFormProps {
  dispatch: React.Dispatch<{ type: 'add' | 'remove' | 'reset'; deviceId: string }>;
  onChange?: (state: MeterCreateInput) => void;
  meter?: Meter;
}

function CreateMeterSegmentForm(props: CreateMeterFormProps) {
  const { dispatch, onChange, meter } = props;
  const [formState, setFormState] = useState<MeterCreateInput>({
    deviceId: '',
    type: '',
  });

  useEffect(() => {
    onChange?.(formState);
  }, [onChange, formState]);
  useEffect(() => {
    if (meter) {
      setFormState({
        deviceId: meter.deviceId,
        type: meter.type,

        id: meter.id,
      });
    }
  }, []);

  const onErrorStateChange = useCallback(
    (error: boolean, deviceId: string) => {
      if (error) {
        dispatch({ type: 'add', deviceId });
      } else {
        dispatch({ type: 'remove', deviceId });
      }
    },
    [dispatch]
  );

  const onDeviceIdChange = useCallback((newDeviceId: string) => {
    setFormState((currentState) => ({ ...currentState, deviceId: newDeviceId }));
  }, []);

  const handleChange = useCallback((event: SelectChangeEvent) => {
    setFormState((currentState) => ({ ...currentState, type: event.target.value as string }));
  }, []);

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '1rem',
          paddingRight: '2rem',
          width: '100%',
        }}
      >
        <div>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Typ</InputLabel>
              <Select
                fullWidth
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={formState.type}
                label='Typ'
                onChange={handleChange}
              >
                <MenuItem value={DEVICE_TYPES.GATEWAY}>Gateway</MenuItem>
                <MenuItem value={DEVICE_TYPES.METER}>Warmwasserzähler</MenuItem>
                <MenuItem value={DEVICE_TYPES.SMOKEDETECTOR}>Rauchmelder</MenuItem>
              </Select>
              <TextInput
                className='storageSegmentTextInput'
                label='Geräte ID'
                startValue={formState.deviceId}
                onChange={onDeviceIdChange}
                onErrorStateChange={(error) => onErrorStateChange(error, 'deviceId')}
                sx={{
                  display: 'flex',
                }}
              />
            </FormControl>
          </Box>
        </div>
      </Box>
    </Box>
  );
}
