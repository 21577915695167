import React, { createContext, useContext, useEffect, useState } from 'react';

import Api from '../api/Api';
import Tenant from '../api/models/Tenant.model';

interface TenantContextType {
  tenants: Tenant[];
  updateTenants: VoidFunction;
}

const TenantContext = createContext<TenantContextType | undefined>(undefined);

export const useTenants = () => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
};

interface TenantProviderProps {
  propertyId: string;
  children: React.ReactNode;
}

export const TenantProvider: React.FC<TenantProviderProps> = ({ propertyId, children }) => {
  const [tenants, setTenants] = useState<Tenant[]>([]);

  useEffect(() => {
    Api.getPropertyTenants(propertyId).then((tenants) => setTenants(tenants));
  }, [propertyId]);

  const updateTenants = () => {
    Api.getPropertyTenants(propertyId).then((tenants) => {
      setTenants(tenants);
    });
  };

  return (
    <TenantContext.Provider
      value={{
        tenants,
        updateTenants,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};
