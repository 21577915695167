import './App.css';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { darkTheme, theme } from './theme/Theme';

import { AuthProvider } from './contexts/auth/AuthProvider';
import ContentRouter from './router/ContentRouter';
import CustomersProvider from './contexts/customers/CustomersProvider';
import { Login } from './pages/login/Login';
import MenuWrapper from './menuWrapper/MenuWrapper';
import ProductsProvider from './contexts/products/ProductsProvider';
import PropertiesProvider from './contexts/properties/PropertiesProvider';
import React from 'react';
import RequireAuth from './contexts/auth/RequireAuth';
import SettingsProvider from './contexts/settings/SettingsProvider';
import { ThemeProvider } from '@emotion/react';
import TitleProvider from './contexts/title/TitleProvider';

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* Providers */}
      {/* Content */}

      <ContentRouter />

      {/* /Content */}

      {/* /Providers */}
    </ThemeProvider>
  );
}

export default App;
