import { Navigate } from "react-router";
import useAuth from "./useAuth";

interface RequireAuthProps {
  children: React.ReactChild;
}

/**
 * Wrapper that makes sure the children are only rendered when the user is authenticated.
 * @param props the children to be rendered when the user is authenticated
 */
export default function RequireAuth(props: RequireAuthProps) {
  let auth = useAuth();

  if (!auth.user) {
    return <Navigate to="/login" replace />;
  }

  return <>{props.children}</>;
}
