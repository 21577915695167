import { createContext, useContext } from 'react';

import Property from '../../api/models/Property.model';

interface PropertiesContextType {
  properties?: Property[];
  updateProperties: VoidFunction;
}

export const PropertiesContext = createContext<PropertiesContextType>(null!);

export default function useProperties() {
  return useContext(PropertiesContext);
}
