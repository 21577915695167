import { createContext, useContext } from "react";

interface TitleContextType {
  title: string;
  setSubTitle: (newTitle: string) => void;
}

export const TitleContext = createContext<TitleContextType>(null!);

export default function useTitle() {
  return useContext(TitleContext);
}
