import React, { createContext, useContext, useEffect, useState } from 'react';

import Api from '../api/Api';
import Meter from '../api/models/Meter.model';

interface HardwareContextType {
  meters: Meter[];
  updateHardware: VoidFunction;
}

const HardwareContext = createContext<HardwareContextType | undefined>(undefined);

export const useHardware = () => {
  const context = useContext(HardwareContext);
  if (!context) {
    throw new Error('useHardware must be used within a HardwareProvider');
  }
  return context;
};

interface HardwareProviderProps {
  propertyId: string;
  children: React.ReactNode;
}

export const HardwareProvider: React.FC<HardwareProviderProps> = ({ propertyId, children }) => {
  const [meters, setMeters] = useState<Meter[]>([]);

  useEffect(() => {
    Api.getPropertyMeters(propertyId).then((meters) => setMeters(meters));
  }, [propertyId]);

  const updateHardware = () => {
    Api.getPropertyMeters(propertyId).then((meters) => {
      setMeters(meters);
    });
  };

  return (
    <HardwareContext.Provider
      value={{
        meters,
        updateHardware,
      }}
    >
      {children}
    </HardwareContext.Provider>
  );
};
