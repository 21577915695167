import Product from "./Product.model";

export default class StoragePosition {
  constructor(
    readonly id: number,
    readonly level: number,
    readonly xPosition: number,
    readonly yPosition: number,
    readonly productId: number,
    readonly product: Product,
    readonly status: Status
  ) {}
}

export enum Status {
  Good = "GOOD",
  Critical = "CRITICAL",
  Normal = "NORMAL",
  Unknown = "UNKNOWN",
}
