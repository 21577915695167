import Meter, { MeterCreateInput } from '../../api/models/Meter.model';
import { useCallback, useState } from 'react';

import Api from '../../api/Api';
import Unit from '../../api/models/Unit.model';

type MeterAction = 'add' | 'update' | 'delete';

export const useMeters = (
  currentUnit?: Unit | undefined,
  setCurrentUnit?: (unit: Unit | undefined) => void
) => {
  const [openMeterDialog, setOpenMeterDialog] = useState<boolean>(false);
  const [currentMeter, setCurrentMeter] = useState<Meter | undefined>();

  const handleAddMeter = useCallback((unitId: string, newMeter: Omit<Meter, 'id'>) => {
    // Logic to handle adding a new meter
    console.log('Add meter to unit:', unitId, newMeter);
  }, []);

  const handleEditMeter = useCallback((unitId: string, meter: Meter) => {
    // Logic to handle editing a meter
    console.log('Edit meter in unit:', unitId, meter);
  }, []);

  const handleDeleteMeter = useCallback((unitId: string, meterId: string) => {
    // Logic to handle deleting a meter
    console.log('Delete meter from unit:', unitId, meterId);
  }, []);

  const openMeterDialogHandler = useCallback(
    (unit: Unit, meter: Meter | undefined = undefined) => {
      if (setCurrentUnit) {
        setCurrentUnit(unit);
      }
      setCurrentMeter(meter);
      setOpenMeterDialog(true);
    },
    [setCurrentUnit]
  );

  const openPropertyMeterDialogHandler = useCallback(
    (propertyId: string | undefined, meter: Meter | undefined = undefined) => {
      if (!propertyId) {
        return;
      }
      setCurrentMeter(meter);
      setOpenMeterDialog(true);
    },
    []
  );

  const closeMeterDialogHandler = useCallback(() => {
    setCurrentMeter(undefined);
    if (setCurrentUnit) {
      setCurrentUnit(undefined);
    }
    setOpenMeterDialog(false);
  }, [setCurrentUnit]);

  const submitMeterDialogHandler = useCallback(
    (data: MeterCreateInput) => {
      console.log('Submit meter:', currentUnit, data);
      // Logic to handle meter creation or update
      if (data.id) {
        patch(data as Meter);
      } else {
        const newData = {
          deviceId: data.deviceId,
          // id: data.id, // ???
        };
        add(newData);
      }

      closeMeterDialogHandler();
    },
    [currentUnit, closeMeterDialogHandler]
  );

  const patch = (updateMeter: Meter) => {
    if (!currentUnit) {
      return;
    }

    Api.updateMeter(updateMeter, updateMeter.id).then((updatedMeter: Meter) => {
      // handleMetertAction(currentUnit, updatedMeter, 'update');
    });
  };

  const add = (newMeter: MeterCreateInput) => {
    if (!currentUnit) {
      return;
    }
    Api.postUnitMeter(currentUnit.id, newMeter).then((createdMeter: Meter) => {
      // handleMetertAction(currentUnit, createdTenant, 'add');
    });
  };

  return {
    handleAddMeter,
    handleEditMeter,
    handleDeleteMeter,
    openMeterDialog,
    currentMeter,
    openMeterDialogHandler,
    openPropertyMeterDialogHandler,
    closeMeterDialogHandler,
    submitMeterDialogHandler,
  };
};
