import { createTheme } from "@mui/material";

export const theme = createTheme({
  shape: {
    borderRadius: 2,
  },
  palette: {
    primary: {
      main: "#0971f1",
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "lightgray",
          },
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
