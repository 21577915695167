import Api from '../api/Api';
import { LoaderFunctionArgs } from 'react-router-dom';
import Property from '../api/models/Property.model';

export async function PropertyLoader({
  params,
}: LoaderFunctionArgs): Promise<{ property: Property }> {
  const { propertyId } = params;

  // TODO handle propertyId undefined

  const property = await Api.getProperty(propertyId as string);

  return { property };
}
