import { createContext, useContext } from "react";
import Settings from "../../api/models/Settings.model";

interface SettingsContextType {
  settings: Settings;
  updateSettings: VoidFunction;
  getStatusFromLevel: Function;
}

export const SettingsContext = createContext<SettingsContextType>(null!);

export default function useCustomers() {
  return useContext(SettingsContext);
}
