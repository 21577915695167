import { JwtPayload, jwtDecode } from 'jwt-decode';

import Api from '../../api/Api';
import { AuthContext } from './useAuth';
import { User } from '../../api/models/User.model';
import { useNavigate } from 'react-router';
import { useState } from 'react';

const ACCESS_TOKEN = 'access_token';

interface AuthProviderProps {
  children: React.ReactChild;
}

/**
 * Provides authentication to child elements. Should be used to make sure the user is logged in.
 * @param props the children in which the authentication should be provided.
 */
export function AuthProvider(props: AuthProviderProps) {
  // init the user state using the localStorage, so the user is logged in directly with a valid access_token
  const navigate = useNavigate();
  const [user, setUser] = useState<User>(updateUser()!);

  const login = (
    mail: string,
    password: string,
    onSuccess?: VoidFunction,
    onFail?: VoidFunction
  ) => {
    console.log('[AuthProvider] login() called.');
    Api.sendLogin(mail, password)
      .then((response) => {
        console.log(response);

        if (response.data?.access_token) {
          // only when the response is a success and the response data contains an access_token,
          // write the access token to the localStorage and run onSuccess
          console.log('login() successful');

          writeAccessToken(response.data.access_token);
          setUser(updateUser()!);

          onSuccess?.();
        } else {
          console.log('login() failed.');
          onFail?.();
        }
      })
      .catch(() => {
        console.log('login() failed.');
        onFail?.();
      });
  };

  const logout = (callback?: VoidFunction) => {
    console.log('[AuthProvider] logout() called.', callback);

    writeAccessToken('');

    if (callback) {
      const path = callback() as unknown as string;
      console.log('to path', path);
      navigate(path, { replace: true });
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>{props.children}</AuthContext.Provider>
  );
}

/**
 * Tries to generate a User object with the data stored in the 'access_token' in the localStorage
 * @returns the User object stored in the 'access_token' JWT in the localStorage,
 *          null if the 'access_token' is expired or not available
 */
function updateUser(): User | null {
  const localStorageAccessToken = readAccessToken();

  if (localStorageAccessToken && localStorageAccessToken !== '') {
    // the local storage access_token is not empty

    // joint data type, since we also store user data in the jwt
    const decodedToken = jwtDecode<JwtPayload & User>(localStorageAccessToken);

    if (!!decodedToken.exp && decodedToken.exp > Date.now() / 1000) {
      // token is not yet expired
      return {
        email: decodedToken.email,
        id: decodedToken.id,
        name: decodedToken.name,
      } as User;
    } else {
      // token is expired or not in the correct format
      return null;
    }
  }

  // token is not available
  return null;
}

export function readAccessToken(): string | null {
  return localStorage.getItem(ACCESS_TOKEN);
}

export function writeAccessToken(newAccessToken: string) {
  return localStorage.setItem(ACCESS_TOKEN, newAccessToken);
}
