import '../management/customers.scss';

import { Accordion, AccordionDetails, AccordionSummary, Box, Paper } from '@mui/material';
import { Outlet, useLoaderData, useLocation, useMatch } from 'react-router-dom';
import { SetStateAction, SyntheticEvent, useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Property from '../../api/models/Property.model';

export default function PropertyDetails() {
  const { property } = useLoaderData() as { property: Property };
  const { pathname } = useLocation();
  const match = useMatch('/property/:propertyId');
  const isOnParentRoute = (match && match.pathname === pathname) || undefined;
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (isOnParentRoute) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [isOnParentRoute, pathname]);

  const handleAccordionChange = (event: SyntheticEvent, expanded: boolean) => {
    setIsExpanded(expanded as SetStateAction<boolean>);
  };

  console.log('------------ ', property, 'in', pathname, match);
  return (
    <>
      {
        <Box>
          <Box className='propertyDetails'>
            <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
              <AccordionSummary
                sx={{ backgroundColor: '#5f3d3d', color: 'white' }}
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                <Box
                  sx={{
                    p: 1,
                    fontSize: '15pt',
                  }}
                >
                  {property.name}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ padding: '1rem' }}>
                  <Box>
                    <strong>Objektnummer:</strong>
                  </Box>
                  <Box>{property.number}</Box>
                </Box>

                <Box sx={{ padding: '1rem' }}>
                  <Box>
                    <strong>Nutzeinheiten:</strong>
                  </Box>
                  <Box>2</Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          <div className='note'>
            TODO: Liegenschaft-Eigenschaften einklappen, wenn auf sub-route
          </div>

          <Outlet context={{ property }} />
        </Box>

        /*  <Paper className="paper">
        <LoadingWrapper watchedData={customers}>
          <TableContainer className="tableContainer" component={Paper}>
            <Table className="table">
              <TableHead className="tableHead">
                <TableRow className="tableRow">
                  <TableCell
                    className="tableCell"
                    align="left"
                    width={1}
                  ></TableCell>
                  <TableCell
                    className="tableCell"
                    align="left"
                    sx={{ paddingLeft: 0 }}
                  >
                    Kunde
                  </TableCell>
                  <div className="rightCells">
                    <TableCell className="tableCell" align="right">
                      Lieferbedarfe
                    </TableCell>
                    <TableCell className="tableCell" align="right">
                      Lagerorte
                    </TableCell>
                  </div>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {customers?.map((customer, index) => (
                  <CustomerExpandableRow
                    customer={customer}
                    key={index}
                  ></CustomerExpandableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </LoadingWrapper>
      </Paper> */
      }
    </>
  );
}
