export class ApiResource {
  public static readonly login = 'auth/login';
  public static readonly register = 'auth/register';

  public static readonly properties = 'properties';
  public static readonly units = 'units';
  public static readonly tenants = 'tenants';
  public static readonly meters = 'meters';

  public static readonly property = (propertyId: string) => this.properties + `/${propertyId}`;
  public static readonly unit = (unitId: string) => this.unit + `/${unitId}`;
  public static readonly tenant = (tenantId: string) => this.tenant + `/${tenantId}`;
  public static readonly meter = (deviceId: string) => this.meter + `/${deviceId}`;

  // _OLD_
  public static readonly products = 'products';
  public static readonly customers = 'customers';
  public static readonly locations = 'locations';
  public static readonly sensors = 'sensor';
  public static readonly unassignedSensors = this.sensors + '/unassigned';
  public static readonly storage = 'storage';
  public static readonly settings = 'settings';

  public static readonly customer = (customerId: number) => this.customers + `/${customerId}/`;

  public static readonly customerLocations = (customerId: number) =>
    this.customer(customerId) + this.locations;

  public static readonly customerSensors = (customerId: number) =>
    this.sensors + '/' + customerId + '/allCustomer';

  public static readonly locationSensors = (locationId: number) =>
    this.sensors + '/' + locationId + '/allLocation';

  public static readonly storageUnitSensor = (storageUnitId: number) =>
    this.sensors + '/' + storageUnitId + '/unit';

  public static readonly storageLocation = (locationId?: number) =>
    this.storage + '/location' + (locationId ? `/${locationId}` : '');

  public static readonly storageUnit = (unitId?: number) =>
    this.storage + '/unit' + (unitId ? `/${unitId}` : '');

  public static readonly storageLocationUnit = (locationId: number) =>
    this.storageLocation(locationId) + '/unit';

  public static readonly positionProduct = (positionId: number) =>
    this.storage + `/position/${positionId}/product`;

  public static readonly positionLevel = (positionId: number) =>
    this.storage + `/position/${positionId}/level`;

  public static readonly product = (productId: number) => this.products + `/${productId}`;

  public static readonly setting = (userId: number) => this.settings + `/${userId}`;

  public static readonly settingTreshhold = (userId: number) =>
    this.settings + `/${userId}/treshold`;
}
