import './products.scss';

import {
  Add as AddIcon,
  DownloadOutlined,
  ElectricMeter,
  SearchOutlined,
} from '@mui/icons-material';
import { Box, Button, InputAdornment, Pagination, Stack, TextField } from '@mui/material';
import Meter, { MeterCreateInput } from '../../api/models/Meter.model';
import { useCallback, useState } from 'react';

import Api from '../../api/Api';
import CreateMeterDialog from '../../components/dialog/createMeterDialog';
import DeleteButton from '../../components/deleteButton/DeleteButton';
import EditIcon from '@mui/icons-material/Edit';
import { useHardware } from '../../contexts/HardwareContext';
import { useParams } from 'react-router';

export default function Hardware() {
  const { meters, updateHardware } = useHardware();
  const { propertyId } = useParams();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [currentMeter, setCurrentMeter] = useState<Meter | undefined>();

  const onAddButtonClick = useCallback(() => {
    setCurrentMeter(undefined);
    setOpenDialog(true);
  }, []);

  const updateDialog = useCallback((meter: Meter) => {
    setCurrentMeter(meter);
    setOpenDialog(true);
  }, []);

  const onDialogClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const onDialogSubmit = useCallback(
    (data: MeterCreateInput) => {
      console.log('data from modal', data);
      if (!propertyId) {
        return;
      }

      if (data.id) {
        console.log('api update', propertyId);

        Api.updateMeter(data as Meter, data.id).then((createdMeter: Meter) => {
          updateHardware();
        });
      } else {
        const newData = {
          deviceId: data.deviceId,
          type: data.type,
          // id: data.id, // ???
        };
        console.log('api create', propertyId, newData);

        Api.postPropertyMeter(propertyId, newData).then((createdMeter: Meter) => {
          updateHardware();
        });
      }
    },
    [updateHardware, propertyId]
  );

  const onUnitDelete = (meter: Meter) => {
    Api.deleteMeter(meter).then(() => {
      updateHardware();
    });
  };

  return (
    <>
      <div className='contentTable'>
        <div className='header'>
          <div className='cell1'>
            <ElectricMeter id='categoryIcon' /> Geräte
          </div>
          <Button
            className='newStorageUnitBtn'
            variant='contained'
            startIcon={<AddIcon />}
            onClick={() => onAddButtonClick()}
          >
            Neues Gerät/Zähler anlegen
          </Button>
        </div>

        <CreateMeterDialog
          open={openDialog}
          onClose={onDialogClose}
          onSubmit={onDialogSubmit}
          meter={currentMeter}
        />

        <Box className='tenant-toolbar'>
          <Button variant='outlined' startIcon={<DownloadOutlined />}>
            Ausgewählte exportieren (csv)
          </Button>
          <div className='gap'></div>
          <TextField
            id='outlined-basic'
            label='Gerät Suchen..'
            variant='outlined'
            size='small'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <div className='divTableContent table-header'>
          <div className='customer'>
            <strong>Gerätenummer</strong>
          </div>

          <div className='customer'>
            <strong>Geräte-Typ</strong>
          </div>

          <div className='editBtn'>
            <strong>Actions</strong>
          </div>
          <div className='deleteBtn'></div>
        </div>

        {meters?.map((meter, index) => {
          return (
            <div className='divTableContent' key={'meter-' + index}>
              <div className='customer'>{meter.deviceId}</div>
              <div className='customer'>{meter.type}</div>

              <div className='editBtn'>
                <div onClick={() => updateDialog(meter)}>
                  <EditIcon />
                </div>
              </div>

              <div className='deleteBtn'>
                <DeleteButton item={meter.deviceId} onConfirm={() => onUnitDelete(meter)} />
              </div>
            </div>
          );
        })}
      </div>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}
      >
        <Stack spacing={2}>
          <Pagination count={10} />
        </Stack>
      </Box>
    </>
  );
}
