import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import Api from '../api/Api';
import Tenant from '../api/models/Tenant.model';
import Unit from '../api/models/Unit.model';

type TenantAction = 'add' | 'update' | 'delete';

interface UnitsContextType {
  units: Unit[];
  updateUnits: VoidFunction;
  updateUnitTenants: (unit: Unit) => void;
  updateUnitMeters: (unit: Unit) => void;
  handleTenantAction: (unit: Unit, tenant: Tenant | string, action: TenantAction) => void;
}

const UnitsContext = createContext<UnitsContextType | undefined>(undefined);

export const useUnits = () => {
  const context = useContext(UnitsContext);
  if (!context) {
    throw new Error('useUnits must be used within a UnitsProvider');
  }
  return context;
};

interface UnitsProviderProps {
  propertyId: string;
  children: React.ReactNode;
}

export const UnitsProvider: React.FC<UnitsProviderProps> = ({ propertyId, children }) => {
  const [units, setUnits] = useState<Unit[]>([]);

  useEffect(() => {
    Api.getUnits(propertyId).then((units) => setUnits(units));
  }, [propertyId]);

  const updateUnits = () => {
    Api.getUnits(propertyId).then((units) => {
      setUnits(units);
    });
  };

  const updateUnitTenants = (mUnit: Unit) => {
    Api.getUnitTenants(mUnit.id).then((tenants) => {
      setUnits((prevUnits) =>
        prevUnits.map((unit) =>
          unit.id === mUnit.id
            ? {
                ...unit,
                tenants,
              }
            : unit
        )
      );
    });
  };

  const updateUnitMeters = (mUnit: Unit) => {
    Api.getUnitMeters(mUnit.id).then((meters) => {
      setUnits((prevUnits) =>
        prevUnits.map((unit) =>
          unit.id === mUnit.id
            ? {
                ...unit,
                meters,
              }
            : unit
        )
      );
    });
  };

  const handleTenantAction = useCallback(
    (mUnit: Unit, tenant: Tenant | string, action: TenantAction) => {
      setUnits((prevUnits) =>
        prevUnits.map((unit) => {
          if (unit.id === mUnit.id) {
            if (action === 'add' && typeof tenant !== 'string') {
              return { ...unit, tenants: [...unit.tenants, tenant] };
            }
            if (action === 'update' && typeof tenant !== 'string') {
              return {
                ...unit,
                tenants: unit.tenants.map((t) => (t.id === tenant.id ? tenant : t)),
              };
            }
            if (action === 'delete' && typeof tenant === 'string') {
              return { ...unit, tenants: unit.tenants.filter((t) => t.id !== tenant) };
            }
          }
          return unit;
        })
      );
    },
    []
  );

  return (
    <UnitsContext.Provider
      value={{
        units,
        updateUnits,
        updateUnitTenants,
        updateUnitMeters,
        handleTenantAction,
      }}
    >
      {children}
    </UnitsContext.Provider>
  );
};
