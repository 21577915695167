import { createContext, useContext } from "react";
import Customer from "../../api/models/Customer.model";

interface CustomersContextType {
  customers: Customer[];
  updateCustomers: VoidFunction;
}

export const CustomersContext = createContext<CustomersContextType>(null!);

export default function useCustomers() {
  return useContext(CustomersContext);
}
