import './products.scss';

import {
  Add as AddIcon,
  DownloadOutlined,
  Key,
  SearchOffOutlined,
  SearchOutlined,
} from '@mui/icons-material';
import { Box, Button, Checkbox, InputAdornment, Pagination, Stack, TextField } from '@mui/material';

import Api from '../../api/Api';
import DeleteButton from '../../components/deleteButton/DeleteButton';
import EditIcon from '@mui/icons-material/Edit';
import Tenant from '../../api/models/Tenant.model';
import { useTenants } from '../../contexts/TenantContext';

export default function Tenants() {
  const { tenants, updateTenants } = useTenants();

  const onTenantDelete = (tenant: Tenant) => {
    Api.deleteTenant(tenant).then(() => {
      updateTenants();
    });
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <>
      <div className='contentTable'>
        <div className='header'>
          <div className='cell1'>
            <Key id='categoryIcon' />
            Bewohner
          </div>
        </div>

        <div className='note'>
          <div>- Veraltung um Daten der Mieter zu bearbeiten zu suchen</div>
          <div>- Tabellarische darstellung</div>
          <div>
            - Zuordnung zur Nutzeinheit/Wohnung dargestellt (Jedoch keine Zuordnung hier möglich)
          </div>
          <div>- Exportmöglichkeiten etc</div>
        </div>

        <Box>
          <Box className='tenant-toolbar'>
            <Button variant='outlined' startIcon={<DownloadOutlined />}>
              Ausgewählte exportieren (csv)
            </Button>

            <div className='gap'></div>

            <TextField
              id='outlined-basic'
              label='Mieter Suche..'
              variant='outlined'
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <div className='divTableContent table-header'>
            <Box className='customer '>
              <Checkbox {...label} />
            </Box>
            <div className='customer'>
              <strong>Name</strong>
            </div>
            <div className='customer'>
              <strong>E-Mail</strong>
            </div>
            <div className='customer'>
              <strong>Uvi</strong>
            </div>
          </div>

          {tenants?.map((tenant, index) => {
            return (
              <div className='divTableContent' key={index}>
                <Box className='customer '>
                  <Checkbox {...label} />
                </Box>
                <div className='customer'>{tenant.name}</div>
                <div className='customer'>test@email.com</div>
                <div className='customer'>an</div>
              </div>
            );
          })}
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}
        >
          <Stack spacing={2}>
            <Pagination count={10} />
          </Stack>
        </Box>
      </div>
    </>
  );
}
